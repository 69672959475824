import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { I18nextProvider } from 'react-i18next'
import { createI18next } from './i18n'
import { isBrowser, getPageLanguage } from './utils'
import { MetaContext, MetaContextType } from './store'

import './styles/global.scss'
import { navigate } from 'gatsby'

type SiteMetaRes = APIResponse<Record<string, string>>

interface WrapPageProps {
  children: React.ReactNode
  path: string
  pageContext: {
    language: string
    intl: any
    isI18nPage: boolean
    siteMeta?: SiteMetaRes
    serverData: { siteMeta: SiteMetaRes }
  }
}

const WrapPage: FC<WrapPageProps> = (props) => {
  const { children, pageContext, path } = props
  const i18n = createI18next(pageContext.language)
  const {
    intl,
    siteMeta = undefined,
    serverData = { siteMeta: { data: { attributes: {} } } },
  } = pageContext
  const [ready, setReady] = useState(false)

  useEffect(() => {
    const isI18nPage = path.includes(`/en/`) || path.includes(`/zh/`)

    if (isBrowser() && !isI18nPage) {
      const { search } = location
      const detected = getPageLanguage()
      const { originalPath } = intl
      const queryParams = search || ''
      const newUrl = `${location.origin}/${detected}${
        originalPath.endsWith('/') ? originalPath : originalPath + '/'
      }${queryParams}`
      localStorage.setItem('language', detected)

      // no way
      setTimeout(() => {
        navigate(newUrl)
      }, 100)

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {}
    }

    if (isBrowser() && isI18nPage) {
      setReady(true)
    }
  }, [intl, path])
  return (
    <div style={{ opacity: ready ? 1 : 0 }}>
      <Helmet
        htmlAttributes={{ lang: pageContext.language }}
        titleTemplate="%s">
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=0"
        />
        <script
          defer
          data-domain="monadventures.com"
          src="https://plausible.io/js/script.js"></script>
      </Helmet>
      <I18nextProvider i18n={i18n}>
        <MetaContext.Provider
          value={
            siteMeta
              ? (siteMeta.data.attributes as unknown as MetaContextType)
              : (serverData.siteMeta?.data?.attributes as MetaContextType) || {
                  nav: {
                    about: '',
                    team: '',
                    portfolio: '',
                    services: '',
                    news: '',
                    contact_us: '',
                    weibo: '',
                    jobs: '',
                    wechat: '',
                    address: '',
                    address1: '',
                    address2: '',
                    copyright: '',
                  },
                  contact: {
                    title: '',
                    description: '',
                    bp: '',
                    bp_email: '',
                    hr: '',
                    hr_email: '',
                  },
                }
          }>
          {children}
        </MetaContext.Provider>
      </I18nextProvider>
    </div>
  )
}

export default WrapPage
